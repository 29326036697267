<template>
  <div class="employees-modify">
    <v-container class="employees-modify__container" fluid>
      <entity-modify-header class="mb-10" :title="title" />

      <form-builder v-if="!isLoading" :schema="$options.schema" :initial-data="initialData" @submit="submit">
        <template #footer="{ valid }">
          <v-row class="mt-10">
            <v-col>
              <v-btn color="secondary" block class="primary--text" :to="backRoute">{{ $t('button.back') }}</v-btn>
            </v-col>
            <v-col>
              <v-btn type="submit" color="primary" block :disabled="!valid" :loading="isUpload">
                {{ submitButtonText }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </form-builder>
    </v-container>
  </div>
</template>

<script>
// Services
import usersService from '@/services/users';
import analyticsService from '@/services/analytics';
import { normalizeEmployee } from '@/services/serializations';

// Components
import EntityModifyHeader from '@/components/EntityModifyHeader.vue';
import FormBuilder from '@/components/schema/FormBuilder.vue';

// Models
import { schema } from '@/schemas/employee.schema';
import { createModelData } from '@/schemas/createModelData';
import notificationService from '@/services/notification';

// Utils
import { getCredentialsFromSections } from '@/utils/employees';

// Constants
import { EMPLOYEES, EMPLOYEES_DETAILED } from '@/constants/routes';
import { CREATE_EMPLOYEES, EDIT_EMPLOYEES } from '@/constants/analyticsActions';

export default {
  name: 'EmployeesModify',

  components: { EntityModifyHeader, FormBuilder },

  props: {
    isEdit: { type: Boolean, default: false },
    id: { type: Number, default: null },
    prevPage: { type: Number, default: 1 },
  },

  data() {
    return {
      isLoading: false,
      initialData: createModelData(schema),
      isUpload: false,
    };
  },

  computed: {
    submitButtonText() {
      return this.isEdit ? this.$t('button.edit_employee') : this.$t('button.add_employees');
    },

    backRoute() {
      return this.isEdit
        ? { name: EMPLOYEES_DETAILED, params: { id: this.id } }
        : { name: EMPLOYEES, query: { page: this.prevPage || 1 } };
    },

    title() {
      return this.isEdit ? this.$t('employee.edit_employee') : this.$t('employee.add_employee');
    },
  },

  mounted() {
    if (this.isEdit) {
      this.isLoading = true;
      usersService
        .getEmployeeById(this.id)
        .then(employee => {
          this.initialData = normalizeEmployee(employee);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  },

  methods: {
    async submit(data) {
      if (this.isUpload) {
        return;
      }
      this.isUpload = true;
      const body = { ...data };
      const roles = this.formatRoles(data.roles);
      body.roles = roles;

      const saveClient = this.isEdit ? usersService.updateEmployeeById : usersService.addEmployee;
      try {
        const employee = await saveClient({ ...body, employeeId: this.id });
        analyticsService.track(this.isEdit ? EDIT_EMPLOYEES : CREATE_EMPLOYEES);
        notificationService.success(this.isEdit ? this.$t('employee.edited') : this.$t('employee.created'), 2000);
        this.$router.push({ name: EMPLOYEES_DETAILED, params: { id: employee.id || this.id } });
      } catch (error) {
        this.$options.notificationItem = notificationService.error(
          `${this.$t('error.found_errors')} ${Object.keys(error?.response?.data).join(', ')}`
        );
      } finally {
        this.isUpload = false;
      }
    },

    formatDataSection(data) {
      const sections = [];
      Object.keys(data.sections).forEach(key => {
        if (data.sections[key].length) {
          data.sections[key].forEach(value => {
            sections.push({ name: key, type: value });
          });
        }
      });
      return sections;
    },

    formatRoles(roles) {
      return roles.map(role => {
        return {
          role: role.role.id,
          projects: {
            assignAllCurrentAndFuture: role.projects.all,
            all: role.projects.all,
            include: role.projects.include.map(project => project.id),
          },
        };
      });
    },

    getCredentialsFromSections,

    getMultiSelectObjectFromProjects(employee) {
      const multiSelectObject = { ...employee.projects };
      multiSelectObject.exclude = this.extractProjectIds(multiSelectObject.exclude);
      multiSelectObject.include = this.extractProjectIds(multiSelectObject.include);
      return multiSelectObject;
    },

    extractProjectIds(projects) {
      return Array.isArray(projects) ? projects.map(project => project.id) : [];
    },
  },

  schema,
  notificationItem: null,
};
</script>

<style lang="scss">
.employees-modify {
  &__container {
    max-width: 548px !important;
  }
}
</style>
